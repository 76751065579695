import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Card,
  Form,
  Button,
  InputGroup,
  FormControl,
  DropdownButton,
  Dropdown,
  Alert,
  Table
} from 'react-bootstrap'
import './findUserFull.sass'

import avatar1 from '../../assets/images/user/avatar-1.jpg'
import avatar2 from '../../assets/images/user/avatar-2.jpg'
import avatar3 from '../../assets/images/user/avatar-3.jpg'

import { CONFIG } from '../../config/constant'
import { BASE } from '../../basedata/constants'

import { useForm } from 'react-hook-form'

export function ListApprovers() {
  const [userList, setUserList] = useState([])

  const [userSearch, setUserSearch] = useState({
    identifier: '',
    type: 'externalId'
  })

  const [userNotFound, setUserNorFound] = useState(false)
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
    getValues
  } = useForm()

  const getApprovers = () => {
    fetch(`${BASE.api.base_url}${BASE.api.access_approver}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: CONFIG.API_KEY
      }
    })
      .then((resg) => resg.json())
      .then((response) => {
        if (response.length < 1) {
          setTimeout(() => {
            setUserNorFound(false)
          }, 4000)
        }
        setUserList(response)
      })
      .catch((error) => {
        setTimeout(() => {
          setUserNorFound(false)
        }, 4000)
      })
  }

  const deleteApprover = (approverId: any) => {
    if (!confirm('Tem certeza?')) {
      return false
    }
    fetch(`${BASE.api.base_url}${BASE.api.access_approver}/${approverId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': BASE.api.api_key
      },
      body: JSON.stringify({
        id: approverId
      })
    })
      .then((res) => res.json())
      .then((response) => {
        getApprovers()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    getApprovers()
  }, [])

  return (
    <Card className="">
      <Card.Header>
        <Card.Title as="h5" className="search-header">
          Aprovadores cadastrados:
        </Card.Title>
      </Card.Header>
      <Card.Body className="px-0 py-2">
        {userNotFound && (
          <Alert variant="warning">Usuário não encontrado</Alert>
        )}
        <Table responsive hover>
          <tbody>
            {userList &&
              userList.map((user: any) => (
                <tr key={user.hikiId} className="unread">
                  <td colSpan={2}>
                    <p>Nome</p>
                    <h6 className="mb-0">{user.name}</h6>
                  </td>
                  <td colSpan={2}>
                    <p>Departamento</p>
                    <h6 className="mb-0">{user.departament}</h6>
                  </td>
                  <td colSpan={2}>
                    <p className="text-muted">
                      <a
                        className="text-decoration-none"
                        onClick={() => {
                          deleteApprover(user.id)
                        }}
                      >
                        Excluir
                      </a>
                    </p>
                  </td>
                  {/* <td colSpan={2}>
                    <p className="text-muted">
                      <a className="text-decoration-none">Excluir</a>
                    </p>
                  </td> */}
                </tr>
              ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  )
}
