import React, { useCallback, useState } from 'react'

import './ticketControl.sass'

import  SearchTicket from '../components/ticketSearch'
import  TakeFoto from '../components/photoTake'
import  QrCode from '../components/qrCode'


export default function TicketControl() {

  const [flowState, setFlowState] = useState(0)
  
  const handleResponse = useCallback((flow: number) => {
    setFlowState(flow)
  }, [])

  return (
    <>
    {flowState === 0 && <SearchTicket onResponse={handleResponse} />}
    {flowState === 1 && <TakeFoto onResponse={handleResponse} />}
    {flowState === 2 && <QrCode onResponse={handleResponse} />}
    </>

  )
}
