import React, { useContext } from 'react'

import './Loader.sass'

const Loader = () => {

    return (
        <div className='local-loader'>
            <i
                className="fa fa-spinner fa-spin  f-10 m-r-15"
                title="oiee"
            />
        </div>
    )
}

export default Loader
