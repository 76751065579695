export const BASE = {
  api: {
    base_url: 'https://botafogo-dashboard-api-74hf2txj6q-ue.a.run.app',
    user_validate: '/user/validate',
    user_create: '/user',
    validate_register_number: '/multiclubes/user/validate',
    send_face: '/biometry',
    visitor_create: '/visitor',
    visitor_report: '/visitor/report',
    valid_mask: '/biometry/validate/mask',
    valid_glasses: '/biometry/validate/glasses',
    create_face: '/biometry/create',
    remove_background: '/biometry/removeBackground',
    // ADEMIR
    access_create: '/access',
    access_edit: '/access',
    access_find: '/access',
    access_login: '/access/login',
    access_search: '/access/search',
    hik_get_status: '/hikvision/local/getDeviceInfo',
    find_user_with_biometry: '/biometry/find/users',
    remove_biometry: '/biometry',
    // access data
    access_approver: '/approvers',
    access_reports: '/access-control/report',
    access_report_entrance: '/access/status',
    access_report_allusers: '/access/allusers',
    log_create: '/logs',
    get_log_from_gate: '/access-control/today',
    api_key:
      'EBWEOLQZTGLJNUYOTDUJVFEVJCGUROOWUSQUOEEHBHGPYJAPSIPQUDQZWAUMEWHESOOUDEAOYNIOYOXABRBJYXCQBRXNKSCFZBXJUFLOIBRGVCAIDUKMVYGVLXSL',
    token:
      'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnYiOiJsb2NhbCIsInVzZXJFbWFpbCI6InRlc3RlOUBhbmFsaXN0YS5jb20iLCJ1c2VySWQiOjEyMSwidXNlclJvbGUiOjEsInVzZXJEZWFsZXIiOjI3LCJkZWFsZXJaaXBDb2RlIjoiNzY5NjMtNzQ5Iiwia2lsbCI6MTYyNzM4NDEwMTI1MiwiaWF0IjoxNjI3Mzg0MTAxfQ.X7oGIOFKZDbfjO6BnlVkMA_y1aik8YwBV-Y8Liu0CvU'
  },
  ticket_api: {
    base_url: 'https://botafogo-game-api-74hf2txj6q-ue.a.run.app',
    user_validate: '/tickets/search',
    getQrCode: '/access-control/getQrCode',
    generateLog: '/access-control/generate-log',
    generateAccess: '/access-control'
  },
  local_api: {
    base_url: 'https://6f996a2d5f61.sa.ngrok.io',
    user_validate: '/user/validate',
    user_create: '/user',
    validate_register_number: '/multiclubes/user/validate',
    send_face: '/biometry',
    visitor_create: '/visitor',
    visitor_report: '/visitor/report',
    valid_mask: '/biometry/validate/mask',
    valid_glasses: '/biometry/validate/glasses',
    create_face: '/biometry/create',
    remove_background: '/biometry/removeBackground',
    // ADEMIR
    access_create: '/access',
    access_edit: '/access',
    access_find: '/access',
    access_login: '/access/login',
    access_search: '/access/search',
    hik_get_status: '/hikvision/local/getDeviceInfo',
    find_user_with_biometry: '/biometry/find/users',
    remove_biometry: '/biometry',
    // access data
    access_report_entrance: '/access/status',
    access_report_allusers: '/access/allusers',
    log_create: '/logs',
    api_key:
      'EBWEOLQZTGLJNUYOTDUJVFEVJCGUROOWUSQUOEEHBHGPYJAPSIPQUDQZWAUMEWHESOOUDEAOYNIOYOXABRBJYXCQBRXNKSCFZBXJUFLOIBRGVCAIDUKMVYGVLXSL',
    token:
      'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnYiOiJsb2NhbCIsInVzZXJFbWFpbCI6InRlc3RlOUBhbmFsaXN0YS5jb20iLCJ1c2VySWQiOjEyMSwidXNlclJvbGUiOjEsInVzZXJEZWFsZXIiOjI3LCJkZWFsZXJaaXBDb2RlIjoiNzY5NjMtNzQ5Iiwia2lsbCI6MTYyNzM4NDEwMTI1MiwiaWF0IjoxNjI3Mzg0MTAxfQ.X7oGIOFKZDbfjO6BnlVkMA_y1aik8YwBV-Y8Liu0CvU'
  },
  api_user: {
    base_url: 'https://api.bepass.com.br',
    user_validate: '/user/validate',
    user_create: '/user',
    user_edit: '/user',
    validate_register_number: '/multiclubes/user/validate',
    send_face: '/biometry',
    visitor_create: '/visitor',
    visitor_find_by_document: '/visitor/findByDocument',
    access_create: '/access/create',
    access_edit: '/access/edit',
    access_get: '/access',
    log_create: '/logs',
    valid_mask: '/biometry/validate/mask',
    valid_glasses: '/biometry/validate/glasses',
    create_face: '/biometry/create',
    remove_background: '/biometry/removeBackground',
    // ADEMIR
    hik_get_cards: '/hikvision/status/card',
    find_user_with_biometry: '/biometry/find/users',
    remove_biometry: '/biometry',
    api_key:
      'EBWEOLQZTGLJNUYOTDUJVFEVJCGUROOWUSQUOEEHBHGPYJAPSIPQUDQZWAUMEWHESOOUDEAOYNIOYOXABRBJYXCQBRXNKSCFZBXJUFLOIBRGVCAIDUKMVYGVLXSL',
    token:
      'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnYiOiJsb2NhbCIsInVzZXJFbWFpbCI6InRlc3RlOUBhbmFsaXN0YS5jb20iLCJ1c2VySWQiOjEyMSwidXNlclJvbGUiOjEsInVzZXJEZWFsZXIiOjI3LCJkZWFsZXJaaXBDb2RlIjoiNzY5NjMtNzQ5Iiwia2lsbCI6MTYyNzM4NDEwMTI1MiwiaWF0IjoxNjI3Mzg0MTAxfQ.X7oGIOFKZDbfjO6BnlVkMA_y1aik8YwBV-Y8Liu0CvU'
  }
}
