import React, { Component, useCallback, useEffect, useState } from 'react'
import { Row, Col, Card, Tab, Tabs, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import avatar1 from '../../assets/images/user/avatar-1.jpg'
import avatar2 from '../../assets/images/user/avatar-2.jpg'
import avatar3 from '../../assets/images/user/avatar-3.jpg'

import {
  BsEmojiSunglasses,
  BsSun,
  BsPeople,
  BsPersonBoundingBox,
  BsEmojiWink
} from 'react-icons/bs'
import { VisitorCreate } from '../components/visitorCreate'
import { AccessLog } from '../components/accessLog'
import { FindUser } from '../components/findUser'
import { OpenGate } from '../components/openGate'
import { FindUserFull } from '../components/findUserFull'
import { TotalUsers } from '../components/totalUsers'
import { DailyAccess } from '../components/dailyAccess'
import { GateFlow } from '../components/gateFlow'
import { EntranceStatus } from '../components/EntranceStatus'
import { ExitStatus } from '../components/ExitStatus'

import { CONFIG } from '../../config/constant'
import { BASE } from '../../basedata/constants'

function Monitoring() {
  const currentUser = sessionStorage.getItem('role')

  if (currentUser === 'default') {
    window.location.href = '/dashboard'
  }

  const [photoState, setphotoState] = useState<string>('')

  const handleResponse = useCallback((message: string) => {
    setphotoState(message)
  }, [])

  const [entranceToday, setEntranceToday] = useState(0)
  const [exitToday, setExitToday] = useState(0)
  const [reportUsers, setReportUsers] = useState(0)
  const [gateList, setGateList] = useState([{}])

  const gates = [
    {
      name: 'Matarazzo',
      direction: 'Entraram',
      group: 'Catraca 1',
      IP: '192.168.0.168',
      sync: true
    },
    {
      name: 'Matarazzo',
      direction: 'Entraram',
      group: 'Catraca 2',
      IP: '192.168.0.171',
      sync: false
    },
    {
      name: 'Matarazzo',
      direction: 'Sairam',
      group: 'Catraca 1',
      IP: '192.168.0.169',
      sync: false
    },
    {
      name: 'Matarazzo',
      direction: 'Sairam',
      group: 'Catraca 2',
      IP: '192.168.0.172',
      sync: false
    },
    {
      name: 'Palestra',
      direction: 'Entraram',
      group: 'Catraca 1',
      IP: '192.168.0.174',
      sync: false
    },
    {
      name: 'Palestra',
      direction: 'Entraram',
      group: 'Catraca 2',
      IP: '192.168.0.177',
      sync: false
    },
    {
      name: 'Palestra',
      direction: 'Sairam',
      group: 'Catraca 1',
      IP: '192.168.0.175',
      sync: false
    },

    {
      name: 'Palestra',
      direction: 'Sairam',
      group: 'Catraca 2',
      IP: '192.168.0.178',
      sync: false
    },
    {
      name: 'Estacionamento',
      direction: 'Entraram',
      group: 'Catraca 1',
      IP: '192.168.0.180',
      sync: false
    },
    {
      name: 'Estacionamento',
      direction: 'Entraram',
      group: 'Catraca 2',
      IP: '192.168.0.183',
      sync: false
    },
    {
      name: 'Estacionamento',
      direction: 'Sairam',
      group: 'Catraca 1',
      IP: '192.168.0.181',
      sync: false
    },

    {
      name: 'Estacionamento',
      direction: 'Sairam',
      group: 'Catraca 2',
      IP: '192.168.0.184',
      sync: false
    },
    {
      name: 'Conveniência',
      direction: 'Entraram',
      group: 'Catraca 1',
      IP: '192.168.0.186',
      sync: false
    },
    {
      name: 'Conveniência',
      direction: 'Entraram',
      group: 'Catraca 2',
      IP: '192.168.0.189',
      sync: false
    },
    {
      name: 'Conveniência',
      direction: 'Sairam',
      group: 'Catraca 1',
      IP: '192.168.0.187',
      sync: false
    },

    {
      name: 'Conveniência',
      direction: 'Sairam',
      group: 'Catraca 2',
      IP: '192.168.0.190',
      sync: false
    }
  ]

  const getTotalVisits = useCallback((countValue: any) => {
    return true
  }, [])

  // Get bellow info
  const allGateStatus = () => {
    const requestgates = gates.filter((gate) => gate.sync)

    // return false

    fetch(`${BASE.local_api.base_url}${BASE.local_api.hik_get_status}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: CONFIG.API_KEY
      },
      body: JSON.stringify({ devices: requestgates })
    })
      .then((resg) => resg.json())
      .then((response) => {
        setGateList(response)
        let temp_entrance = 0,
          temp_exit = 0

        const exitIps = [
          '192.168.0.169',
          '192.168.0.172',
          '192.168.0.175',
          '192.168.0.178',
          '192.168.0.181',
          '192.168.0.184',
          '192.168.0.187',
          '192.168.0.190'
        ]
        for (const item of response) {
          if (exitIps.indexOf(item.device.IP) >= 0) {
            temp_exit = temp_exit + item.responseAccess
          } else {
            temp_entrance = temp_entrance + item.responseAccess
          }
        }

        console.log(temp_exit)
        console.log(temp_entrance)
        setEntranceToday(temp_entrance)
        setExitToday(temp_exit)
        setReportUsers(response[0].responseFaces)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    allGateStatus()
  }, [])

  // setInterval(allGateStatus, 10000)
  return (
    <>
      <Row>
        <Col sm={12} md={6}>
          <Card>
            <Card.Header>
              <Card.Title>Matarazzo</Card.Title>
            </Card.Header>
            <Col sm={12}>
              <GateFlow
                sendVisits={getTotalVisits}
                gate={[
                  '192.168.0.168',
                  '192.168.0.169',
                  '192.168.0.171',
                  '192.168.0.172'
                ]}
              />
            </Col>
          </Card>
        </Col>
        <Col sm={12} md={6}>
          <Card>
            <Card.Header>
              <Card.Title>Palestra</Card.Title>
            </Card.Header>
            <Col sm={12}>
              <GateFlow
                sendVisits={getTotalVisits}
                gate={[
                  '192.168.0.174',
                  '192.168.0.175',
                  '192.168.0.177',
                  '192.168.0.178'
                ]}
              />
            </Col>
          </Card>
        </Col>
        <Col sm={12} md={6}>
          <Card>
            <Card.Header>
              <Card.Title>Estacionamento</Card.Title>
            </Card.Header>
            <Col sm={12}>
              <GateFlow
                sendVisits={getTotalVisits}
                gate={[
                  '192.168.0.183',
                  '192.168.0.181',
                  '192.168.0.184',
                  '192.168.0.180'
                ]}
              />
            </Col>
          </Card>
        </Col>
        <Col sm={12} md={6}>
          <Card>
            <Card.Header>
              <Card.Title>Conveniência</Card.Title>
            </Card.Header>
            <Col sm={12}>
              <GateFlow
                sendVisits={getTotalVisits}
                gate={[
                  '192.168.0.186',
                  '192.168.0.189',
                  '192.168.0.187',
                  '192.168.0.190'
                ]}
              />
            </Col>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xl={6}>
          <TotalUsers totalData={reportUsers} />
        </Col>
      </Row>
    </>
  )
}

export default Monitoring
