import React, { useCallback, useEffect, useState } from 'react'
import clubLogo from './images/club_logo.svg'
import bepassLogo from './images/bepass_logo.png'
import batfair from './images/betfair_logo.png'
import './App.sass'

import './index.scss'

import FaceOnboard from './Biometric/FaceOnboard'
import FaceInstructions from './Biometric/FaceInstructions'
import UserSignup from './User/UserSignup'
import Welcome from './Welcome'

import DashDefault from './views/Dashboard/Dashboard'
import { SideBar } from './shared/sidebar'
import Monitoring from './views/Monitoring/Monitoring'

import { BrowserRouter, Route, Link, Routes } from 'react-router-dom'
import Login from './views/Auth/Login'
import VisitorsList from './views/VisitorList/Visitors'
import CreateUser from './views/Auth/CreateUser'
import EditUser from './views/Auth/EditUser'
import Approvers from './Approvers/Approvers'
import Reports from './views/Reports/Reports'
import TicketControl from './views/TicketControl/TicketControl'

function App() {
  return (
    <>
      {/* <DashDefault /> */}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/control"
            element={
              <TicketControl />
            }
          />
          <Route
            path="/monitoring"
            element={
              <>
                <SideBar />
                <div className="pcoded-main-container">
                  <div className="pcoded-content">
                    <Monitoring />
                  </div>
                </div>
              </>
            }
          />
          <Route
            path="/reporting"
            element={
              <>
                <SideBar />
                <div className="pcoded-main-container">
                  <div className="pcoded-content">
                    <Reports />
                  </div>
                </div>
              </>
            }
          />
          <Route
            path="/visitors"
            element={
              <>
                <SideBar />
                <div className="pcoded-main-container">
                  <div className="pcoded-content">
                    <VisitorsList />
                  </div>
                </div>
              </>
            }
          />
          <Route
            path="/user/create"
            element={
              <>
                <SideBar />
                <div className="pcoded-main-container">
                  <div className="pcoded-content">
                    <CreateUser />
                  </div>
                </div>
              </>
            }
          />
          <Route
            path="/approvers"
            element={
              <>
                <SideBar />
                <div className="pcoded-main-container">
                  <div className="pcoded-content">
                    <Approvers />
                  </div>
                </div>
              </>
            }
          />
          <Route
            path="/user/edit/:id"
            element={
              <>
                <SideBar />
                <div className="pcoded-main-container">
                  <div className="pcoded-content">
                    <EditUser />
                  </div>
                </div>
              </>
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App
